import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Icon, StyledWrapper, Text, PageLoader, Price, BackLink } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';
import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';
import { getText } from '../../../i18n';

import ROUTES from '../../bi/constants/routes';
import { TAXI_CONFLICTS_TYPE } from '../../bi/constants/checkout';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import { AGGREGATORS_ACCOUNT, CART_WARN_COMPANIES } from '../../bi/constants/accounts';

import TripName from '../../components/TripName';
import DuplicateDialog from '../../components/DuplicateDialog';
import { TaxiConflictDialog } from '../../components/TaxiConflictDialog';
import DiscountDialog, { ItemType } from '../../components/DiscountDialog';
import RateDialog from '../../components/RateDialog';
import DialogRules from '../../components/RulesDialog';
import CheckoutItem from './components/CheckoutItem';
import AnalyticsBar from '../../components/AnalyticsBar';
import { CartWarning } from '../../components/UnderageWarning';
import { BookingErrorDialog } from '../../components/BookingErrorDialog';

import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';
import {
  getPriceAllCertificates,
  checkForDuplicateInCart,
  checkForDuplicateTrips,
  isAirConflicts,
} from '../../bi/utils/airline';
import parseSearchString from '../../bi/utils/convertSearchParams';
import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { employeeFullNameFromCart } from '../../bi/utils/employees';

import { IAppStore, ICompanyFunds } from '../../bi/types/app';
import { PAYMENT_METHODS } from '../../bi/types/paymentMethod';

import workspace from '../../bi/services/workspace';
import App from '../../bi/services/app';
import Notepad from '../../bi/services/notepad';
import UserSession from '../../bi/services/userSession';
import { IMatch } from '../../bi/types/shared';
import CustomAnalyticsService from '../../bi/services/customAnalytics';
import AccountSettings from '../../bi/services/accountSettings';
import CloudPaymentService from '../../bi/services/cloudPayment';
import Booking from '../../bi/services/booking';
import EmployeePayment from '../../bi/services/employeePayment';
import EventService from '../../bi/services/events';
import CheckoutService from '../../bi/services/checkout';
import { PersonalPaymentProtocol } from '../../bi/protocols/personalPayment';
import { ICompany } from '../../bi/services/workspace/types';
import { ICheckoutDataItem, ICheckoutStore, IConflicts, ISourcesItem } from '../../bi/services/checkout/types';
import { IProjects } from '../../bi/types/employees';
import { IPassportView } from '../../bi/services/employee/types';
import FeatureFlags from '../../bi/services/featureFlags';
import Intercom from '../../bi/services/chat/intercom';
import { BookingStore } from '../../bi/services/booking/analogStore';
import AirlineBaggageService from '../../bi/services/airlineBaggage';
import { AirlineBaggageStore } from '../../bi/services/airlineBaggage/store';

import styles from './index.module.css';

const BILL_TITLES = {
  SHOW: getText('checkout:bill.show'),
  SHOW_FEW: getText('checkout:bill.showFew'),
  NOTSHOW: getText('checkout:bill.notShow'),
  NOTSHOWMANY: getText('checkout:bill.notShowMany'),
};

const LABELS = {
  TITLE: getText('checkout:title'),
  TITLE_BOOK_TRIP: getText('checkout:titleBookTrip'),
  BOOK_TRIP_LABEL: getText('checkout:bookTripLabel'),
  ATTENTION: getText('checkout:finish.attention'),
  CHECKBOX: {
    ONE: getText('checkout:finish.checkbox.one'),
    TWO: getText('checkout:finish.checkbox.two'),
  },
  PAY: getText('checkout:finish.pay'),
  CART: getText('checkout:finish.cart'),
  TRIP_BOOKING: getText('checkout:tripBooking'),
  REQUEST_ERROR: {
    WITH_TYPE: (serviceType: string, price: string, currency: string, message: string) =>
      getText('booking:requestError.withType', { serviceType, price, currency, message }),
    WITHOUT_TYPE: (message: string) => getText('booking:requestError.withoutType', { message }),
  },
  CURRENCY: getText('booking:currency'),
};

interface ICheckoutProps {
  history: RouteComponentProps['history'];
  checkoutService: CheckoutService;
  workspaceService: workspace;
  appService: App;
  notepadService: Notepad;
  userSessionService: UserSession;
  match: IMatch;
  aggregationId: number | null;
  customAnalyticsService: CustomAnalyticsService;
  accountSettingsService: AccountSettings;
  featureFlagsService: FeatureFlags;
  cloudPaymentService: CloudPaymentService;
  bookingService: Booking;
  employeePaymentService: EmployeePayment;
  eventService: EventService;
  personalPaymentProtocol: PersonalPaymentProtocol;
  chatService: Intercom,
  stores: {
    bookingStore: typeof BookingStore
  },
  airlineBaggageService: AirlineBaggageService;
  airlineBaggageStore: AirlineBaggageStore;
}

interface ICheckoutState {
  id: string | null;
  sources: ISourcesItem[];
  name: string;
  loading: boolean;
  minDate: Moment | null;
  maxDate: Moment;
  price: number;
  accept: boolean;
  checkoutData: ICheckoutDataItem[];
  conflicts: IConflicts;
  discountSapsan: ItemType[];
  userAnalytics: any[];
  showDialog: boolean;
  duplicateDialog: boolean;
  discountDialog: boolean;
  rateDialog: boolean;
  isMultiCompany: boolean;
  companies: ICompany[];
  projects: IProjects[];
  documentType: Partial<IPassportView>;
  isFiltersHotelsInMicroservice: boolean;
  companyFunds?: ICompanyFunds[];
  taxiDialogWrongBothDates?: boolean;
  taxiDialogWrongStartDate?: boolean;
  isCancelling: boolean,
}

@withStores([MOBX_STORES.BOOKING])
@observer
class Checkout extends Component<ICheckoutProps, ICheckoutState> {
  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();
  unsubscribeFn: () => void;
  unsubscribeAppFn: () => void;

  constructor(props: ICheckoutProps) {
    super(props);

    const {
      id,
      sources,
      name,
      loading,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      discountSapsan,
      userAnalytics,
    } = props.checkoutService.get();

    const userSessionServiceGet = props.userSessionService.get();

    this.state = {
      id,
      sources,
      name,
      loading,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      discountSapsan,
      userAnalytics,
      showDialog: false,
      duplicateDialog: false,
      discountDialog: false,
      rateDialog: false,
      isCancelling: false,
      isMultiCompany: props.workspaceService.get().Companies.length > 1,
      companies: props.workspaceService.get().Companies,
      projects: userSessionServiceGet.projects,
      documentType: userSessionServiceGet.enums.documents,
      isFiltersHotelsInMicroservice: this.props.featureFlagsService.getFiltersHotelsInMicroservice(),
    };
  }

  componentDidMount() {
    const { checkoutService, appService, match, customAnalyticsService } = this.props;
    const id = match.params.id;

    this.unsubscribeFn = checkoutService.subscribeCart(this.updateState);
    this.unsubscribeAppFn = appService.subscribe(this.updateCompanyFunds);

    checkoutService.start();
    checkoutService.load(id);
    checkoutService.loadPreCheckout(id);
    checkoutService.loadDiscount(id);
    customAnalyticsService.getAnalytics();
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    this.unsubscribeAppFn();
    this.props.checkoutService.clearState();
  }

  updateCompanyFunds = ({ header: { CompanyFunds } }: IAppStore) => this.setState({ companyFunds: CompanyFunds });

  updateState = ({
    sources,
    loading,
    name,
    id,
    minDate,
    maxDate,
    price,
    accept,
    checkoutData,
    conflicts,
    discountSapsan,
    userAnalytics,
  }: ICheckoutStore) => {
    const userSessionServiceGet = this.props.userSessionService.get();
    const { cartConflicts, tripConflicts, rateConflicts, taxiConflicts } = conflicts;
    const duplicateDialog = cartConflicts.length > 0 || tripConflicts.length > 0;
    const rateDialog = rateConflicts;
    const taxiDialogWrongBothDates = taxiConflicts.length > 0 &&
      !!taxiConflicts.find(({ Type }) => Type === TAXI_CONFLICTS_TYPE.WRONG_BOTH_DATES);
    const taxiDialogWrongStartDate = taxiConflicts.length > 0 &&
      !!taxiConflicts.find(({ Type }) => Type === TAXI_CONFLICTS_TYPE.WRONG_START_DATE);
    const companyFunds: ICompanyFunds[] = this.props.appService.get().header.CompanyFunds;

    this.setState({
      sources,
      loading,
      name,
      id,
      minDate,
      maxDate,
      price,
      accept,
      checkoutData,
      conflicts,
      companyFunds,
      duplicateDialog,
      taxiDialogWrongBothDates,
      taxiDialogWrongStartDate,
      rateDialog,
      discountSapsan,
      userAnalytics,
      projects: userSessionServiceGet.projects,
      documentType: userSessionServiceGet.enums.documents,
    });
  };

  handleGoToCart = () => this.props.history.push(ROUTES.CART.MAIN);

  handleGoToTaxiSearch = () => this.props.history.push(ROUTES.SEARCH.TAXI);

  setDuplicateDialog = (value: boolean) => this.setState({
    duplicateDialog: value,
  });

  setTaxiDialog = (field: string, value: boolean) => {
    if (field === TAXI_CONFLICTS_TYPE.DIALOG_WRONG_BOTH_DATES) return this.handleGoToCart();

    // @ts-ignore
    return this.setState({ [field]: value });
  };

  setDiscountDialog = (value: boolean) => this.setState({
    discountDialog: value,
  });

  setRateDialog = (value: boolean) => this.setState({
    rateDialog: value,
  });

  setRulesDialog = (value: boolean) => {
    if (value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.OPENINGTARIFFRULES);
    }

    this.setState({
      showDialog: value,
    });
  };

  handleGoToBook = (id: string) => {
    const { history } = this.props;
    const searchParams = parseSearchString(location.search);

    if (searchParams?.chosenApprovedRequestId) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      // @ts-ignore
      currentUrlParams.set('chosenApprovedRequestId', searchParams.chosenApprovedRequestId);

      return history.push(`/cart/booking/${id}?${currentUrlParams.toString()}`);
    }

    return history.push(`/cart/booking/${id}`);
  };

  handleRenameTrip = (name: string) => {
    const { id } = this.state;

    this.props.checkoutService.rename({ name, id });
  };

  determineConflict = () => {
    const {
      conflicts: {
        cartConflicts,
        duplicateCartTrips,
        tripConflicts,
      },
    } = this.state;

    if (cartConflicts.length > 0 && isAirConflicts(cartConflicts)) {
      const cancellationAirItems = checkForDuplicateInCart(cartConflicts);

      if (cancellationAirItems) {
        return cancellationAirItems;
      }
    }

    if (duplicateCartTrips.length > 0 && isAirConflicts(duplicateCartTrips)) {
      // TODO разница типов
      // @ts-ignore
      return checkForDuplicateTrips(duplicateCartTrips, tripConflicts);
    }

    return null;
  };

  handleChangeCheckbox = (value: boolean) => this.props.checkoutService.changeAccept(value);

  handlePay = (id: string) => {
    const {
      cloudPaymentService: { currentPaymentMethod, openPaymentWidget },
      bookingService: { startBookingPaymentMethods },
      employeePaymentService: { store: { commission } },
      personalPaymentProtocol: { customerReceipt, prepareWidgetInfo },
    } = this.props;

    const {
      price,
      name,
      minDate,
      maxDate,
      sources,
      isFiltersHotelsInMicroservice,
    } = this.state;

    if (currentPaymentMethod === PAYMENT_METHODS.EMPLOYEE_CARD) {
      const amountToPay = price + commission;
      // @ts-ignore
      const receipt = customerReceipt(sources, amountToPay);
      const date = formatRangeDateWithSimplicity(minDate?.isValid() ? minDate : maxDate, maxDate);
      // @ts-ignore
      const employeesList = employeeFullNameFromCart(sources);

      // @ts-ignore
      startBookingPaymentMethods({ Id: this.state.id }, currentPaymentMethod)
        // @ts-ignore
        // eslint-disable-next-line consistent-return
        .then(({ OrderId, ErrorMessages }) => {
          if (ErrorMessages && ErrorMessages?.length) {
            return null;
          }

          const description = `${LABELS.TRIP_BOOKING} ${name}, ${date}, ${employeesList}`;
          const info = prepareWidgetInfo(OrderId as string, receipt, amountToPay, description);
          // @ts-ignore
          openPaymentWidget({
            ...info,
            onSuccess: () => {
              this.handleGoToBook(id);
            },
            onFail: () => {},
          });
        });

      return null;
    }

    const hotelOrders = sources.filter(({ ServiceType }) => ServiceType === SERVICETYPE.HOTEL);

    if (isFiltersHotelsInMicroservice && hotelOrders.length) {
      const preparedOrders = hotelOrders.map(({ Data }) => {
        const jsonData = JSON.parse(Data);

        return {
          idHotel: jsonData.hotel.ClassificatorId,
          rateName: jsonData.room.Name.toLowerCase(),
          rateSum: jsonData.room.Price.TotalPrice,
        };
      });

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAY_SCREEN_PAY, {
        orders: preparedOrders,
      });
    }

    if (!isFiltersHotelsInMicroservice || !hotelOrders.length) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_PAY_SCREEN_PAY);
    }

    if (this.state.discountSapsan.length) {
      return this.setDiscountDialog(true);
    }

    return this.handleGoToBook(id);
  };

  handleGetRules = (itemId: number) => this.props.checkoutService.cancellationInfo(itemId);

  handleApplyDiscount = (value: string) => this.props.checkoutService.applyDiscount(value)
    .finally(() => {
      this.setDiscountDialog(false);
      this.handleGoToBook(this.state.id as string);
    });

  handleClickErrorBookingDialog = () => {
    this.cancelBooking();
    this.props.bookingService.resetStore();
  };

  handleSendRequest = () => {
    const {
      bookingService,
      chatService,
      workspaceService: { isDemo },
      stores: { bookingStore: { bookingInfo: { errors } } },
    } = this.props;

    const error = errors.map(({ CartItem, Message }: any) => {
      const data = JSON.parse(CartItem.Data);
      const serviceType = bookingService.getServiceName(CartItem.ServiceType, data);

      return CartItem ?
        LABELS.REQUEST_ERROR.WITH_TYPE(serviceType, CartItem.Price, LABELS.CURRENCY, Message) :
        LABELS.REQUEST_ERROR.WITHOUT_TYPE(Message);
    }).join('; ');

    if (!isDemo) {
      chatService.requestError(error);
    }

    MainAnalytic.send(
      MainAnalytic.CATEGORY.PAYMENT,
      MainAnalytic.ACTIONS.PAYMENT.REQUESTSINTRAVELASSISTANT,
    );
  };

  cancelBooking = (route = ROUTES.CART.MAIN) => {
    const { isCancelling } = this.state;

    if (isCancelling) {
      return;
    }

    this.setState({
      loading: true,
      isCancelling: true,
    }, async () => {
      const {
        match: { params: { id } },
        bookingService: { cancelBookingPaymentMethods },
        history,
      } = this.props;

      try {
        await cancelBookingPaymentMethods(id);
      } finally {
        this.setState({
          loading: false,
        }, () => history.push(route));
      }
    });
  };

  renderTripName = (priceAllCertificates: number) => {
    const { eventService } = this.props;
    const {
      name,
      price,
      minDate,
      maxDate,
    } = this.state;

    const hasBaggageOffers = !!Object.keys(this.props.airlineBaggageStore.baggageOffers)?.length;

    return (
      <div className={ styles.trip_container }>
        <TripName
          name={ name }
          price={ price + priceAllCertificates }
          minDate={ minDate as Moment }
          maxDate={ maxDate }
          isCheckout
          onChange={ this.handleRenameTrip }
          eventService={ eventService }
          onClick={ () => {} }
          hasBaggageOffers={ hasBaggageOffers }
          airlineBaggageServiceDeleteOffers={
            () => this.props.airlineBaggageService.deleteBaggageOffers()
          }
          qaAttrName={ QA_ATTRIBUTES.cart.checkout.editName.name }
          qaAttrClose={ QA_ATTRIBUTES.cart.checkout.editName.close }
          qaAttrSave={ QA_ATTRIBUTES.cart.checkout.editName.success }
          qaAttrPrice={ QA_ATTRIBUTES.cart.checkout.price }
        />
      </div>
    );
  };

  renderLoading = () => (
    <PageLoader />
  );

  renderPassportWarn = () => {
    const { cloudPaymentService: { isPersonalPaymentMethod } } = this.props;

    if (CART_WARN_COMPANIES.includes(
      this.props.workspaceService.getCompanyData().CompanyId,
    )) {
      return (
        <div className={ styles.warning }>
          <CartWarning type='PASSPORT_WARN'/>
        </div>
      );
    }

    if (isPersonalPaymentMethod) {
      return null;
    }

    return <div className={ styles.delimiter } />;
  };

  renderCheckoutList = () => {
    const { documentType, sources, projects } = this.state;
    const {
      workspaceService,
      notepadService,
      checkoutService,
      accountSettingsService,
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
      cloudPaymentService: { isPersonalPaymentMethod },
    } = this.props;

    const tripAnalytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);
    const cartEmployeeBirthday = accountSettingsService.getCartEmployeeBirthday();
    const listHtml = sources.map((item, index) => (
      <div
        key={ `checkout-item__${index}` }
        className={ styles.item }
      >
        <CheckoutItem
          item={ item }
          projects={ projects }
          isPersonalPaymentMethod={ isPersonalPaymentMethod }
          allowedEmployees={ item.Employees }
          documentType={ documentType }
          checkoutService={ checkoutService }
          notepadService={ notepadService }
          isMulti={ workspaceService.isMultiCompany() }
          customAnalytics={ tripAnalytics }
          cartEmployeeBirthday={ cartEmployeeBirthday }
          isPersonalBonusCards={ this.getPersonalBonusCards }
          qaAttrPrice={ QA_ATTRIBUTES.cart.checkout.itemPrice }
        />
      </div>
    ));

    return (
      <StyledWrapper
        className={ styles.list }
      >
        <div className={ styles.cartList }>
          { listHtml }
        </div>
        { this.renderPassportWarn() }
        { this.renderFinishStep() }
      </StyledWrapper>
    );
  };

  renderFinishStep = () => {
    const { accept, checkoutData, isMultiCompany, id, companies, companyFunds } = this.state;
    const { workspaceService, aggregationId, cloudPaymentService: { isPersonalPaymentMethod } } = this.props;

    let title = null;

    const canBookArray: boolean[] = [];
    const bookMessages = {
      enabled: [],
      disabled: [],
    };

    const companiesBill: ICompany[] = [];
    checkoutData.forEach(({ Company }) => {
      const companyBill = companies.find(item => item.CompanyName === Company);

      if (companyBill) companiesBill.push(companyBill);
    });

    if (companiesBill.length === 0) {
      title = BILL_TITLES.SHOW;
    } else {
      const showArr = companiesBill.filter(item => item.ShowFinanceDetails);

      if (showArr.length === companiesBill.length) title = showArr.length > 1 ? BILL_TITLES.SHOW_FEW : BILL_TITLES.SHOW;

      if (showArr.length === 0) title = BILL_TITLES.NOTSHOW;
    }

    const billHtml = checkoutData.map((bill, index) => {
      const newBill = { ...bill };

      if (workspaceService.isDisabledAccount) {
        newBill.State = workspaceService.bookStatusEnum.DisabledAccount;
      }

      const checkResult = workspaceService.checkAccessRights(newBill, companyFunds as ICompanyFunds[]);
      canBookArray.push(checkResult.canBook);
      // @ts-ignore
      bookMessages[checkResult.canBook ? 'enabled' : 'disabled'].push(checkResult.msg);

      let billTitle = null;

      if (!title) {
        const currentCompany = companies.find(item => item.CompanyName === bill.Company);
        billTitle = currentCompany?.ShowFinanceDetails
          ? BILL_TITLES.SHOW
          : BILL_TITLES.NOTSHOWMANY;
      }

      const companyNameContent = isPersonalPaymentMethod
        ? null
        : isMultiCompany && (<Text color='gray'>&nbsp;({ bill.Company })</Text>);
      const billTitleContent = !!billTitle && (<Text className={ styles.title }>{ billTitle }</Text>);

      return (
        <div key={ index } className={ styles.bill }>
          { billTitleContent }
          <div className={ styles.content }>
            <Price
              marginSmall
              value={ bill.AmountToPay }
              type='bold_24'
              className={ styles.price }
              qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.price }
            />
            { companyNameContent }
          </div>
        </div>
      );
    });

    const canBook = canBookArray.every(Boolean);
    const bookMsg = bookMessages[canBook ? 'enabled' : 'disabled'];
    const selectTitle = aggregationId === AGGREGATORS_ACCOUNT.CKR ? LABELS.BOOK_TRIP_LABEL : LABELS.PAY;

    const attentionContent = canBook && accept && (
      <Text className={ styles.text } color='red'>{ LABELS.ATTENTION }</Text>
    );

    const acceptRulesCheckbox = canBook && (
      <div className={ styles['accept-rules-wrapper'] }>
        <Checkbox
          value={ accept }
          onChange={ this.handleChangeCheckbox }
          qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.acceptRules }
        >
          { LABELS.CHECKBOX.ONE }
        </Checkbox>
        <Button
          type='textual'
          data-qa={ QA_ATTRIBUTES.cart.checkout.finish.link }
          onClick={ () => {
            this.setRulesDialog(true);
          } }
        >
          &nbsp; { LABELS.CHECKBOX.TWO }
        </Button>
      </div>
    );

    const bookMsgContent = !canBook && (
      <div className={ styles['book-unavailable'] }>
        <Icon type='warning' className={ styles.icon }/>
        <div className={ styles.messages }>
          {bookMsg.map((msg, index) => (
            <Text key={ index } color='red' className={ styles.message }>{ msg }</Text>
          ))}
        </div>
      </div>
    );
    const titleHtml = isPersonalPaymentMethod ? null : <Text type='NORMAL_18'>{ title }</Text>;

    return (
      <div className={ styles['finish-step'] }>
        { titleHtml }
        { billHtml }
        { acceptRulesCheckbox }
        { bookMsgContent }
        <div className={ styles['action-wrapper'] }>
          <Button
            type='secondary'
            disabled={ !canBook || !accept }
            onClick={ () => this.handlePay(id as string) }
            qaAttr={ QA_ATTRIBUTES.cart.checkout.finish.buy }
          >
            { selectTitle }
          </Button>
          { attentionContent }
        </div>
      </div>
    );
  };

  renderRulesDialog = () => {
    const { sources, showDialog } = this.state;

    return (
      <DialogRules
        show={ showDialog }
          // @ts-ignore
        items={ sources }
        onClose={ () => this.setRulesDialog(false) }
        onGetRules={ this.handleGetRules }
      />
    );
  };

  renderDuplicateDialog = () => {
    const { duplicateDialog } = this.state;

    const isConflict = this.determineConflict();

    return (
    // @ts-ignore
      <DuplicateDialog
        show={ duplicateDialog }
        conflicts={ this.state.conflicts } // TODO разница типов
        onClose={ () => this.setDuplicateDialog(false) }
        onBack={ this.handleGoToCart }
        isConflict={ isConflict }
      />
    );
  };

  renderTaxiDialog = () => {
    const { taxiDialogWrongBothDates, taxiDialogWrongStartDate, sources, conflicts: { taxiConflicts } } = this.state;

    return (
      <TaxiConflictDialog
        showWrongStartDate={ taxiDialogWrongStartDate as boolean }
        showWrongBothDates={ taxiDialogWrongBothDates as boolean }
        sources={ sources }
        conflicts={ taxiConflicts }
        onClose={ this.setTaxiDialog }
        onBack={ this.handleGoToCart }
        onBackTaxi={ this.handleGoToTaxiSearch }
      />
    );
  };

  renderDiscountDialog = () => {
    const { discountDialog } = this.state;

    return (
    // @ts-ignore
      <DiscountDialog
        show={ discountDialog }
        items={ this.state.discountSapsan }
        onApply={ this.handleApplyDiscount }
        onClose={ this.setDiscountDialog }
      />
    );
  };

  renderRateDialog = () => {
    const { rateDialog } = this.state;

    return (
      <RateDialog
        show={ rateDialog }
        onClose={ () => this.setRateDialog(false) }
        onBack={ this.handleGoToCart }
      />
    );
  };

  renderCustomAnalytics = () => {
    const {
      customAnalyticsService: { store: { sortedCustomAnalytics } },
      cloudPaymentService: { isPersonalPaymentMethod },
    } = this.props;

    if (isPersonalPaymentMethod) {
      return null;
    }

    const { id: cartId, userAnalytics } = this.state;

    const analyticsWhichAppliesToTrip = sortedCustomAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);

    return (
      <AnalyticsBar
        disabled
        analyticsList={ analyticsWhichAppliesToTrip }
        cartId={ cartId }
        userAnalytics={ userAnalytics }
      />
    );
  };

  renderBookingErrorDialog = () => {
    const {
      cloudPaymentService: { isPersonalPaymentMethod },
      stores: { bookingStore: { bookingInfo: { errorMessages, errors, statuses } } },
    } = this.props;

    if (!isPersonalPaymentMethod) {
      return null;
    }

    const showErrorDialog = !!errorMessages.length || !!errors.length;

    return (
      <BookingErrorDialog
        errorMessages={ errorMessages }
        errors={ errors }
        isCancelling={ this.state.isCancelling }
        showErrorDialog={ showErrorDialog }
        statuses={ statuses }
        onClickErrorDialog={ this.handleClickErrorBookingDialog }
        onSendRequest={ this.handleSendRequest }
      />
    );
  };

  render() {
    const { loading, sources } = this.state;
    const { customAnalyticsService: { store: { loading: analyticsLoading } }, aggregationId } = this.props;

    const title = aggregationId === AGGREGATORS_ACCOUNT.CKR ? LABELS.TITLE_BOOK_TRIP : LABELS.TITLE;

    if (loading || analyticsLoading) {
      return this.renderLoading();
    }

    const priceAllCertificates = getPriceAllCertificates(sources);

    return (
      <div className={ styles.wrap }>
        <BackLink
          qaAttr={ QA_ATTRIBUTES.cart.checkout.back }
          text={ LABELS.CART }
          link={ ROUTES.CART.MAIN }
          className={ styles.back }
          alternativeDesign={ isSmartAgent }
        />
        <Text
          qaAttr={ QA_ATTRIBUTES.cart.checkout.title }
          type='bold_32'
          className={ styles.header }
        >
          {title}
        </Text>
        { this.renderTripName(priceAllCertificates) }
        { this.renderCustomAnalytics() }
        { this.renderCheckoutList() }
        { this.renderRateDialog() }
        { this.renderRulesDialog() }
        { this.renderDuplicateDialog() }
        { this.renderTaxiDialog() }
        { this.renderDiscountDialog() }
        { this.renderBookingErrorDialog() }
      </div>
    );
  }
}

export default Checkout;
